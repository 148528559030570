@import url('https://use.typekit.net/cwp1wxo.css');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: white;
  color: black;
  overflow-x: hidden;
}

.container {
  font-family: "inter-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wght" 400;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  padding: 40px 40px 20px;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.content {
  flex-grow: 1;
  max-width: 540px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
}

.subtitle {
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 2rem 0;
  opacity: 0.7;
}

.section {
  margin-bottom: 1.5rem;
}

.section p {
  margin: 0;
}

.italic {
  font-style: italic;
}


.footer {
  padding-top: 2rem;
}

.link {
  color: inherit;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem; /* Reduced spacing between items */
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: fit-content;
  line-height: 1.4; /* Adjust as needed to remove extra space */
}

.link:hover {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 600px) {
  .container {
    padding: 20px;
  }
}